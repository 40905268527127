import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  searchProducts,
  searchProductsError,
  searchProductsSuccess,
} from './actions';
import { getProductsRequest, IGetProductsResponseBody } from '../../../services/product';

function* handleProductsSearch({ payload }: ReturnType<typeof searchProducts>) {
  try {
    const { data }: IGetProductsResponseBody = yield call(getProductsRequest, payload);

    yield put(searchProductsSuccess(data));
  } catch (error) {
    yield put(searchProductsError());
    yield handleError(error);
  }
}

export function* productsSearchSagas(): Generator {
  yield all([
    yield takeLatest(
      searchProducts,
      handleProductsSearch,
    ),
  ]);
}
