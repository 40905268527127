import { createAction } from '@reduxjs/toolkit';
import {
  IGetProductRequestData,
  IGetSimilarProductsRequestData,
  IProductDialog,
  ISimilarProductsNormalizedData,
} from '../types';
import { IProductFull } from '../../../types/product';

export const getProduct = createAction<IGetProductRequestData>('PRODUCT/GET_PRODUCT');
export const getProductSuccess = createAction<IProductFull>('PRODUCT/GET_PRODUCT/SUCCESS');
export const getProductError = createAction('PRODUCT/GET_PRODUCT/ERROR');

export const getSimilarProducts = createAction<IGetSimilarProductsRequestData>('PRODUCT/GET_SIMILAR_PRODUCTS');
export const getSimilarProductsSuccess = createAction<ISimilarProductsNormalizedData>('PRODUCT/GET_SIMILAR_PRODUCTS/SUCCESS');
export const getSimilarProductsError = createAction('PRODUCT/GET_SIMILAR_PRODUCTS/ERROR');

export const openProductDialog = createAction<IGetProductRequestData>('PRODUCT/PRODUCT_DIALOG/OPEN');
export const mergeProductDialog = createAction<Partial<IProductDialog>>('PRODUCT/PRODUCT_DIALOG/MERGE');
export const closeProductDialog = createAction('PRODUCT/PRODUCT_DIALOG/CLOSE');
