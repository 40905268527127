import { getURL, makeRequest } from '../../services/api';
import { IGetHomePromotionsResponseBody, IHomePageAboutUsDataResponseBody } from './types';

export const getHomePromotionsRequest = () => {
  const url = getURL('/front/mainpage/banners');

  return makeRequest<IGetHomePromotionsResponseBody>(url);
};

export const getHomePageDataRequest = () => makeRequest<IHomePageAboutUsDataResponseBody>(getURL('/front/mainpage/info'));
