import queryString from 'query-string';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API_HOST, CookieKey } from '../../constants/common';
import { CityCode } from '../../types/city';
import { ResponseStatus } from '../../types/api';

export const getURL = (path: string, queryParams: Record<string, any> = {}): string => {
  const search = queryString.stringify(queryParams, {
    arrayFormat: 'bracket',
  });

  return (
    `${API_HOST}${path}${search ? `?${search}` : ''}`
  );
};

export const preprocessResponse = <TResponseData>(response: TResponseData & { status: ResponseStatus }): TResponseData => {
  const { status } = response;

  if (status === ResponseStatus.error) {
    throw response as any;
  }

  return response;
};

export const makeRequest = <TResponseData>(
  url: string,
  data?: any & { cityCode?: CityCode },
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET',
): Promise<TResponseData> => {
  const accessToken = Cookies.get(CookieKey.accessToken);
  const { cityCode, ...restData } = data || {};

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (cityCode) {
    headers['city-code'] = cityCode;
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const requestBody = method !== 'GET' && restData
    ? JSON.stringify(restData)
    : undefined;

  return axios({
    url,
    method,
    headers,
    data: requestBody,
  }).then(((response) => preprocessResponse<TResponseData>(response.data)));
};
