import { createReducer } from '@reduxjs/toolkit';
import { IOrderReducer } from '../types';
import {
  postOrder,
  postOrderSuccess,
  postOrderError,
  getOrder,
  getOrderSuccess,
  getOrderError,
} from './actions';

export const initialState: IOrderReducer = {
  isLoading: false,
  order: null,
};

export const orderReducer = createReducer(initialState, (builder) => {
  builder.addCase(postOrder, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(postOrderSuccess, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(postOrderError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getOrder, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOrderSuccess, (state, { payload }) => ({
    ...state,
    order: payload,
    isLoading: false,
  }));
  builder.addCase(getOrderError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
