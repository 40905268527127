import { getURL, makeRequest } from '../../services/api';
import {
  IGetCityGlobalSettingsResponseBody,
  IGetCityGlobalSettingsItemRequestData,
  IGetGlobalSettingsItemRequestData,
  IGetGlobalSettingsResponseBody,
} from './types';

export const getGlobalSettingsRequest = (data : IGetGlobalSettingsItemRequestData) => {
  const url = getURL('/front/global-settings');

  return makeRequest<IGetGlobalSettingsResponseBody>(url, data);
};

export const getCityGlobalSettingsRequest = (data : IGetCityGlobalSettingsItemRequestData) => {
  const url = getURL('/front/city-global-settings');

  return makeRequest<IGetCityGlobalSettingsResponseBody>(url, data);
};
