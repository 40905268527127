import { createAction } from '@reduxjs/toolkit';
import { IGetProductsRequestData } from '../../../services/product';
import { IProductsNormalizedData } from '../../../types/product';

export const getOurRecommendations = createAction <IGetProductsRequestData>(
  'OUR_RECOMMENDATIONS/GET_OUR_RECOMMENDATIONS',
);
export const getOurRecommendationsSuccess = createAction<IProductsNormalizedData>(
  'OUR_RECOMMENDATIONS/GET_OUR_RECOMMENDATIONS/SUCCESS',
);
export const getOurRecommendationsError = createAction('OUR_RECOMMENDATIONS/GET_OUR_RECOMMENDATIONS/ERROR');
