import { createAction } from '@reduxjs/toolkit';
import {
  IProductSearchRequestData, IProductsSearchReducer,
} from '../types';
import { IProducts } from '../../../types/product';

export const setProductsSearch = createAction<Partial<IProductsSearchReducer> | undefined>('PRODUCTS_SEARCH/SET');

export const searchProducts = createAction<IProductSearchRequestData>('PRODUCTS_SEARCH/PRODUCTS_SEARCH');
export const searchProductsSuccess = createAction<IProducts>('PRODUCTS_SEARCH/PRODUCTS_SEARCH/SUCCESS');
export const searchProductsError = createAction('PRODUCTS_SEARCH/PRODUCTS_SEARCH/ERROR');
