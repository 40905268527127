import { createAction } from '@reduxjs/toolkit';
import { IFilterGroups, IGetFiltersRequestData } from '../types';
import { IGetProductsRequestData } from '../../../services/product';
import { IProductsNormalizedData } from '../../../types/product';

export const getProducts = createAction<IGetProductsRequestData>('PRODUCTS/GET_PRODUCTS');
export const getProductsSuccess = createAction<IProductsNormalizedData>('PRODUCTS/GET_PRODUCTS/SUCCESS');
export const getProductsError = createAction('PRODUCTS/GET_PRODUCTS/ERROR');

export const getProductsFilters = createAction<IGetFiltersRequestData>('PRODUCTS/GET_FILTERS');
export const getProductsFiltersSuccess = createAction<IFilterGroups>('PRODUCTS/GET_FILTERS/SUCCESS');
export const getProductsFiltersError = createAction('PRODUCTS/GET_FILTERS/ERROR');
