import { createReducer } from '@reduxjs/toolkit';
import {
  searchProducts,
  searchProductsError,
  searchProductsSuccess,
  setProductsSearch,
} from './actions';
import { IProductsSearchReducer } from '../types';

export const initialState: IProductsSearchReducer = {
  isLoading: false,
  isOpen: false,
  data: [],
  total: 0,
};

export const productsSearchReducer = createReducer(initialState, (builder) => {
  builder.addCase(setProductsSearch, (state, { payload }) => ({
    ...initialState,
    ...payload,
  }));

  builder.addCase(searchProducts, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(searchProductsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(searchProductsError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
