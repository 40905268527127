import { CityCode } from './city';

export interface IBaseRequestBodyWithCityCode {
  cityCode: CityCode;
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface ISortData {
  sortBy?: string;
  sortOrder?: SortOrder;
}

export enum RouteQueryParamsKey {
  sortType = 'sort',
}

export enum ResponseStatus {
  success = 'success',
  error = 'error',
}

export interface IPaginationRequestData {
  page?: number;
  per_page?: number;
}

export interface IPaginationData {
  total: number;
}

export interface IBaseResponseBody<TData> {
  status: ResponseStatus;
  data: TData;
}

export interface IBaseResponseBodyWithPagination<TData> extends IPaginationData, IBaseResponseBody<TData> {
}
