import {
  all, takeLatest, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Router from 'next/router';
import Cookies from 'js-cookie';
import {
  signUp,
  signUpSuccess,
  signUpError,
  signUpConfirm,
  signIn,
  signInSuccess,
  signInError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPassword,
  mergeSignUpConfirmDialog,
  setSignUpConfirmDialog,
  mergeResetPasswordConfirmDialog,
  setResetPasswordConfirmDialog,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordError,
  signOut,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  signInRequest,
  forgotPasswordRequest,
  signUpRequest,
  resetPasswordRequest,
  signUpConfirmRequest,
  updateProfileRequest,
  updatePasswordRequest,
} from '../api';
import { getActiveRouteCityCode, ROUTES_MAP } from '../../../services/routing';
import { CookieKey } from '../../../constants/common';
import {
  getCartByProfile, mergeCartSyncDialog, resetCart, syncCartToProfile,
} from '../../cart/state/actions';
import { cartReducerSelector } from '../../cart/state/selectors';
import { CityCode } from '../../../types/city';
import { IAuthorizedUserResponseData, ISignUpConfirmResponseBody } from '../types';

const SMS_CODE_SENT_MESSAGE = 'На Ваш номер телефону було відправлено смс з кодом підтвердження';

export function* handleSignUp({ payload }: ReturnType<typeof signUp>) {
  try {
    const { data: { message } } = yield call(signUpRequest, payload);
    const { phone } = payload;

    yield put(setSignUpConfirmDialog({
      isOpen: true,
      phone,
    }));
    yield put(signUpSuccess());
    yield call(toast.success, message || SMS_CODE_SENT_MESSAGE);
  } catch (error) {
    yield put(signUpError());
    yield call(handleError, error);
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function* handleSignInSuccess({ access_token, expires_in, userData }: IAuthorizedUserResponseData) {
  yield put(signInSuccess({
    accessToken: access_token,
    data: userData,
  }));

  const cookieExpireDate = new Date(new Date().getTime() + expires_in * 1000);

  yield call(Cookies.set, CookieKey.accessToken, access_token, { expires: cookieExpireDate });
  yield call(Cookies.set, CookieKey.userData, JSON.stringify(userData), { expires: cookieExpireDate });

  yield call(toast.success, 'Вітаємо! 😉');
}

export function* handleSignUpConfirm({ payload }: ReturnType<typeof signUpConfirm>) {
  try {
    const { data }: ISignUpConfirmResponseBody = yield call(signUpConfirmRequest, payload);

    yield call(toast.success, 'Дякуємо за реєстрацію! 😉');

    yield call(handleSignInSuccess, data);

    yield put(setSignUpConfirmDialog());

    const cityCode: CityCode = yield call(getActiveRouteCityCode);
    const { cartToken } = yield select(cartReducerSelector);

    if (Router.router) {
      Router.router.push(ROUTES_MAP.cityHome.createURL(cityCode));
    }

    if (cartToken) {
      yield put(syncCartToProfile({
        cityCode,
        cartToken,
      }));
    }
  } catch (error) {
    yield put(mergeSignUpConfirmDialog({ isLoading: false }));
    yield call(handleError, error);
  }
}

export function* handleSignIn({ payload }: ReturnType<typeof signIn>) {
  try {
    const { data } = yield call(signInRequest, payload);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { userData } = data;

    yield call(handleSignInSuccess, data);

    const cityCode: CityCode = yield call(getActiveRouteCityCode);
    const { cartToken } = yield select(cartReducerSelector);

    if (Router.router && cityCode !== userData.cityCode) {
      Router.router.push(ROUTES_MAP.cityHome.createURL(userData.cityCode));
      toast.success('Вас було перенаправлено на сторінку міста до якого прив\'язаний Ваш профіль');
    }

    if (cartToken && cityCode === userData.cityCode) {
      yield put(mergeCartSyncDialog({ isOpen: true }));
    } else {
      yield put(getCartByProfile({ cityCode }));
    }
  } catch (error) {
    yield put(signInError());
    yield call(handleError, error);
  }
}

export function* handleForgotPassword({ payload }: ReturnType<typeof forgotPassword>) {
  try {
    const { data: { message } } = yield call(forgotPasswordRequest, payload);

    const { phone, password, repeatPassword } = payload;

    yield put(forgotPasswordSuccess());
    yield put(setResetPasswordConfirmDialog({
      isOpen: true,
      phone,
      password,
      repeatPassword,
    }));

    yield call(toast.success, message || SMS_CODE_SENT_MESSAGE);
  } catch (error) {
    yield put(forgotPasswordError());
    yield call(handleError, error);
  }
}

export function* handleResetPassword({ payload }: ReturnType<typeof resetPassword>) {
  try {
    const { data: { message } } = yield call(resetPasswordRequest, payload);

    const cityCode: CityCode = yield call(getActiveRouteCityCode);

    yield put(setResetPasswordConfirmDialog());
    yield call(toast.success, message || 'Новий пароль було встановлено. Будь ласка, увійдіть у свій профіль');

    if (Router.router && cityCode) {
      Router.router.push(ROUTES_MAP.cityHome.createURL(cityCode));
    }
  } catch (error) {
    yield put(mergeResetPasswordConfirmDialog({ isLoading: false }));
    yield call(handleError, error);
  }
}

export function* handleUpdateProfile({ payload }: ReturnType<typeof updateProfile>) {
  try {
    const { data } = yield call(updateProfileRequest, payload);

    yield put(updateProfileSuccess(data));
    yield call(Cookies.set, CookieKey.userData, JSON.stringify(data));
    yield call(toast.success, 'Ваші дані успішно оновлено');
  } catch (error) {
    yield put(updateProfileError());
    yield call(handleError, error);
  }
}

export function* handleUpdatePassword({ payload }: ReturnType<typeof updatePassword>) {
  try {
    yield call(updatePasswordRequest, payload);

    yield put(updatePasswordSuccess());
    yield call(toast.success, 'Ваш пароль успішно оновлено');
  } catch (error) {
    yield put(updatePasswordError());
    yield call(handleError, error);
  }
}

function* handleSignOut() {
  try {
    Cookies.remove(CookieKey.accessToken);
    Cookies.remove(CookieKey.userData);
    yield put(resetCart());

    yield call(toast.info, 'До зустрічі! 😉');
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* profileSagas(): Generator {
  yield all([
    yield takeLatest(
      signUp,
      handleSignUp,
    ),
    yield takeLatest(
      signUpConfirm,
      handleSignUpConfirm,
    ),
    yield takeLatest(
      signIn,
      handleSignIn,
    ),
    yield takeLatest(
      forgotPassword,
      handleForgotPassword,
    ),
    yield takeLatest(
      resetPassword,
      handleResetPassword,
    ),
    yield takeLatest(
      updateProfile,
      handleUpdateProfile,
    ),
    yield takeLatest(
      updatePassword,
      handleUpdatePassword,
    ),
    yield takeLatest(
      signOut,
      handleSignOut,
    ),
  ]);
}
