import queryString from 'query-string';
import Router from 'next/router';
import { CityCode } from '../types/city';
import { IProductFull, ProductSlug } from '../types/product';
import { CategorySlug } from '../types/category';
import { OrderId } from '../types/order';
import { IGetProductsQueryParams } from '../features/products/types';
import { IGetOrdersHistoryQueryParams } from '../features/ordersHistory/types';
import { IBaseCityPageParams, IBaseCreateSeoData } from '../types/page';

const createSEOTitle = (title: string, cityName?: string) => `OL-TEX | ${title} | Інтернет-магазин OL-TEX ${cityName || ''}`;
const createSEODescription = (description: string, cityName?: string) => (
  `${description} ${new Date().getFullYear()}. Доставка оптом по Україні недорого ✅${cityName || ''}`
);

export const createDefaultSEOData = ({ cityName }: Partial<IBaseCreateSeoData> = {}) => ({
  title: createSEOTitle('Купити оптом шкарпетки, колготи, білизну', cityName),
  description: createSEODescription('Купити одяг в роздріб та оптом', cityName),
});

export const ROUTES_MAP = {
  home: {
    path: '/',
  },
  cityHome: {
    path: '/:cityCode',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.cityHome.path.replace(':cityCode', cityCode)
    ),
  },
  catalog: {
    path: '/:cityCode/katalog',
    createURL: (cityCode: CityCode, queryParams?: IGetProductsQueryParams) => {
      const search = queryString.stringify(queryParams || {}, {
        arrayFormat: 'bracket',
      });

      let url = ROUTES_MAP.catalog.path.replace(':cityCode', cityCode);

      if (search) {
        url += `?${search}`;
      }

      return url;
    },
  },
  products: {
    path: '/:cityCode/:category',
    createURL: (cityCode: CityCode, category: CategorySlug, queryParams?: IGetProductsQueryParams) => {
      const search = queryString.stringify(queryParams || {}, {
        arrayFormat: 'bracket',
      });

      let url = ROUTES_MAP.products.path
        .replace(':cityCode', cityCode)
        .replace(':category', category);

      if (search) {
        url += `?${search}`;
      }

      return url;
    },
  },
  brands: {
    path: '/:cityCode/brendy',
    createURL: (cityCode: CityCode, queryParams?: IGetProductsQueryParams) => {
      const search = queryString.stringify(queryParams || {}, {
        arrayFormat: 'bracket',
      });

      let url = ROUTES_MAP.brands.path.replace(':cityCode', cityCode);

      if (search) {
        url += `?${search}`;
      }

      return url;
    },
  },
  promotions: {
    path: '/:cityCode/akcii/:categorySlug',
    createURL: (cityCode: CityCode, categorySlug?: CategorySlug) => (
      ROUTES_MAP.promotions.path
        .replace(':cityCode', cityCode)
        .replace(':categorySlug', categorySlug || '')
    ),
  },
  newProducts: {
    path: '/:cityCode/novynky/:categorySlug',
    createURL: (cityCode: CityCode, categorySlug?: CategorySlug, queryParams?: IGetProductsQueryParams) => {
      const search = queryString.stringify(queryParams || {}, {
        arrayFormat: 'bracket',
      });

      let url = ROUTES_MAP.newProducts.path
        .replace(':cityCode', cityCode)
        .replace(':categorySlug', categorySlug || '');

      if (search) {
        url += `?${search}`;
      }

      return url;
    },
  },
  productPage: {
    path: '/:cityCode/:category/:product',
    createURL: (cityCode: CityCode, category: CategorySlug, product: ProductSlug) => (
      ROUTES_MAP.productPage.path
        .replace(':cityCode', cityCode)
        .replace(':category', category)
        .replace(':product', product)
    ),
    createSEOData: (product: IProductFull, { cityName }: IBaseCreateSeoData) => ({
      title: createSEOTitle(product.title, cityName),
      description: createSEODescription(product.description, cityName),
    }),
  },
  certificates: {
    path: '/:cityCode/sertyfikaty',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.certificates.path.replace(':cityCode', cityCode)
    ),
  },
  aboutUs: {
    path: '/:cityCode/pro-nas',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.aboutUs.path.replace(':cityCode', cityCode)
    ),
  },
  privacyPolicy: {
    path: '/:cityCode/polityka-konfidentsiinosti',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.privacyPolicy.path.replace(':cityCode', cityCode)
    ),
  },
  signUp: {
    path: '/:cityCode/reiestratsiia',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.signUp.path.replace(':cityCode', cityCode)
    ),
  },
  forgotPassword: {
    path: '/:cityCode/zabuly-parol',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.forgotPassword.path.replace(':cityCode', cityCode)
    ),
  },
  resetPassword: {
    path: '/:cityCode/vidnovlennya-parolyu',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.resetPassword.path.replace(':cityCode', cityCode)
    ),
  },
  wholesale: {
    path: '/:cityCode/opt',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.wholesale.path.replace(':cityCode', cityCode)
    ),
  },
  usefulInformation: {
    path: '/:cityCode/korysna-informaciya',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.usefulInformation.path.replace(':cityCode', cityCode)
    ),
  },
  paymentAndShipping: {
    path: '/:cityCode/dostavka-ta-oplata',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.paymentAndShipping.path.replace(':cityCode', cityCode)
    ),
  },
  basket: {
    path: '/:cityCode/koshyk-pokuptsia',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.basket.path.replace(':cityCode', cityCode)
    ),
  },
  order: {
    path: '/:cityCode/zamovlennia/:id',
    createURL: (cityCode: CityCode, id: OrderId) => (
      ROUTES_MAP.order.path
        .replace(':cityCode', cityCode)
        .replace(':id', id)
    ),
  },
  ordersHistory: {
    path: '/:cityCode/zamovlennia/istoriia-zamovlen',
    createURL: (cityCode: CityCode, queryParams?: IGetOrdersHistoryQueryParams) => {
      const search = queryString.stringify(queryParams || {}, {
        arrayFormat: 'bracket',
      });

      let url = ROUTES_MAP.ordersHistory.path.replace(':cityCode', cityCode);

      if (search) {
        url += `?${search}`;
      }

      return url;
    },
  },
  profile: {
    path: '/:cityCode/vash-profil',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.profile.path.replace(':cityCode', cityCode)
    ),
  },
  debitAndCredit: {
    path: '/:cityCode/vash-profil/debit-ta-credit',
    createURL: (cityCode: CityCode) => (
      ROUTES_MAP.debitAndCredit.path.replace(':cityCode', cityCode)
    ),
  },
};

export const getActiveRouteCityCode = () => {
  const { cityCode } = (Router.router?.query || {}) as IBaseCityPageParams;

  return cityCode || null;
};
