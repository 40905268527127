import { getURL, makeRequest } from '../../services/api';
import {
  IGetCartRequestData,
  IAddCartItemRequestData,
  ICartResponseData,
  IPutCartItemRequestData,
  IRemoveCartItemRequestData,
  IGetCartByProfileRequestData,
  ISyncCartToProfileRequestData,
  IGetCheckoutRequestData,
  ICheckoutResponseData,
} from './types';

export const getCartRequest = (requestData: IGetCartRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/getCartByToken'), requestData, 'POST')
);

export const addCartItemRequest = (requestData: IAddCartItemRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/addItem'), requestData, 'POST')
);

export const putCartItemRequest = (requestData: IPutCartItemRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/updateItem'), requestData, 'POST')
);

export const removeCartItemRequest = (requestData: IRemoveCartItemRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/removeItem'), requestData, 'POST')
);

export const getCartByProfileRequest = (requestData: IGetCartByProfileRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/getCartByUser'), requestData, 'POST')
);

export const syncCartToProfileRequest = (requestData: ISyncCartToProfileRequestData) => (
  makeRequest<ICartResponseData>(getURL('/front/cart/attachCartToUser'), requestData, 'POST')
);

export const getCheckoutDataRequest = (requestData: IGetCheckoutRequestData) => (
  makeRequest<ICheckoutResponseData>(getURL('/front/cart/checkout'), requestData, 'POST')
);
