import { createReducer } from '@reduxjs/toolkit';
import { IPromotionsReducer } from '../types';
import {
  getPromotions,
  getPromotionsError,
  getPromotionsSuccess,
} from './actions';

export const initialState: IPromotionsReducer = {
  isLoading: false,
  promotions: [],
};

export const promotionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getPromotions, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getPromotionsSuccess, (state, { payload }) => ({
    ...state,
    promotions: payload,
    isLoading: false,
  }));
  builder.addCase(getPromotionsError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
