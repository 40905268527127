import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getCityGlobalSettings,
  getCityGlobalSettingsSuccess,
  getGlobalSettings,
  getGlobalSettingsSuccess,
} from './actions';
import { getCityGlobalSettingsRequest, getGlobalSettingsRequest } from '../api';

function* handleGetGlobalSettings({ payload }: ReturnType<typeof getGlobalSettings>) {
  try {
    const { data } = yield call(getGlobalSettingsRequest, payload);

    yield put(getGlobalSettingsSuccess(data));
  } catch (error) {
    yield handleError(error);
  }
}

function* handleGetCityGlobalSettings({ payload }: ReturnType<typeof getCityGlobalSettings>) {
  try {
    const { data } = yield call(getCityGlobalSettingsRequest, payload);

    yield put(getCityGlobalSettingsSuccess(data));
  } catch (error) {
    yield handleError(error);
  }
}

export function* globalSettingsSagas(): Generator {
  yield all([
    yield takeLatest(
      getGlobalSettings,
      handleGetGlobalSettings,
    ),
    yield takeLatest(
      getCityGlobalSettings,
      handleGetCityGlobalSettings,
    ),
  ]);
}
