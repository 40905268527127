import { createReducer } from '@reduxjs/toolkit';
import { IGlobalSettingsReducer } from '../types';
import { getCityGlobalSettingsSuccess, getGlobalSettingsSuccess } from './actions';

export const initialState: IGlobalSettingsReducer = {
  data: {
    privacyPolicyPage: {
      text: '',
    },
  },
  cityData: {
    header: {
      phone: '',
    },
    footer: {
      email: '',
      phones: [],
    },
    aboutUsInfo: {
      title: '',
      content: '',
    },
    cityHomePageAbout: {
      title: 'Про нас',
      text: 'Інформація прямує до Вас ...',
    },
    cityHomePageHelpfulInformation: {
      title: 'Корисна інформація',
      text: 'Інформація прямує до Вас ...',
    },
    cityAboutUsPage: {
      content: '',
      notes: '',
    },
    cityWholesalePage: {
      content: '',
    },
    cityHelpfulInformationPage: {
      content: '',
    },
    cityPaymentAndShippingPage: {
      content: '',
      notes: '',
    },
  },
};

export const globalSettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getGlobalSettingsSuccess, (state, { payload }) => {
    const globalSettingsMap = payload.reduce((accumulator, item) => ({
      ...accumulator,
      [item.itemName]: item.data,
    }), {});

    return ({
      ...state,
      data: {
        ...state.data,
        ...globalSettingsMap,
      },
    });
  });

  builder.addCase(getCityGlobalSettingsSuccess, (state, { payload }) => {
    const globalSettingsMap = payload.reduce((accumulator, item) => ({
      ...accumulator,
      [item.itemName]: item.data,
    }), {});

    return ({
      ...state,
      cityData: {
        ...state.cityData,
        ...globalSettingsMap,
      },
    });
  });
});
