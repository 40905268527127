import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { handleError } from '../../../services/sagasErrorHandler';
import { postForm, postFormError, postFormSuccess } from './actions';
import { postFormRequest } from '../api';

function* handlePostForm({ payload }: ReturnType<typeof postForm>) {
  const { formType } = payload;

  try {
    yield call(postFormRequest, payload);
    yield put(postFormSuccess({ formType }));
    yield call(toast.success, 'Форму успішно відправлено 😉');
  } catch (error) {
    yield put(postFormError({ formType }));
    yield handleError(error);
  }
}

export function* formsSagas(): Generator {
  yield all([
    yield takeEvery(
      postForm,
      handlePostForm,
    ),
  ]);
}
