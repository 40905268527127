import { createReducer } from '@reduxjs/toolkit';
import { IProductReducer } from '../types';
import {
  getProduct,
  getProductError,
  getProductSuccess,
  getSimilarProducts,
  getSimilarProductsError,
  getSimilarProductsSuccess,
  mergeProductDialog,
  closeProductDialog,
} from './actions';

export const initialState: IProductReducer = {
  isLoading: false,
  isLoadingSimilarProducts: false,
  product: null,
  similarProducts: {
    ids: [],
    entities: {},
  },
  productDialog: {
    isOpen: false,
    product: null,
  },
};

export const productReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProduct, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getProductSuccess, (state, { payload }) => ({
    ...state,
    product: payload,
    isLoading: false,
  }));
  builder.addCase(getProductError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getSimilarProducts, (state) => ({
    ...state,
    isLoadingSimilarProducts: true,
  }));
  builder.addCase(getSimilarProductsSuccess, (state, { payload }) => ({
    ...state,
    similarProducts: payload,
    isLoadingSimilarProducts: false,
  }));
  builder.addCase(getSimilarProductsError, (state) => ({
    ...state,
    isLoadingSimilarProducts: false,
  }));

  builder.addCase(mergeProductDialog, (state, { payload }) => ({
    ...state,
    productDialog: {
      ...state.productDialog,
      ...payload,
    },
  }));

  builder.addCase(closeProductDialog, (state) => ({
    ...state,
    productDialog: {
      ...initialState.productDialog,
    },
  }));
});
