import { ICategory } from './category';
import { IBrand } from './brand';
import { INormalizedEntities } from './entities';
import { IPaginationData } from './api';

export type ProductSlug = string;
export type ProductId = string;

export type IProductImages = string[] & [string];

export type IProductLabels = {
  isNew?: boolean;
  isTop?: boolean;
  isSale?: boolean;
};

export enum ProductCharacteristicName {
  color = 'колір',
  size = 'розмір',
}

interface IProductCartItemInfo {
  uid: string;
  quantity: number;
  price: number;
  priceDetails: string | null;
}

interface IAttributesCombinations {
  allAvailableToShow: {
    [key in ProductCharacteristicName]?: string[];
  };
  sortedByColors?: {
    [key: string]: string[];
  };
  sortedBySizes?: {
    [key: string]: string[];
  };
  infoByPairs: {
    [key: string]: IProductCartItemInfo;
  };
}

export type IProductCharacteristicValue = string;

export type IProductCharacteristics = { [key in string | ProductCharacteristicName]: IProductCharacteristicValue[] };

export interface IProduct {
  uid: ProductId;
  slug: ProductSlug;
  title: string;
  oldPrice: number | null;
  price: number;
  discount: number | null;
  images: IProductImages;
  category: ICategory;
  labels: IProductLabels;
}

export interface IProductFull extends IProduct {
  packType: string | null;
  description: string;
  defaultQuantity: number;
  defaultAttribute: string;
  attributesCombinations: IAttributesCombinations;
  characteristics: IProductCharacteristics;
  RelatedBrandInfo: IBrand;
}

export type IProducts = IProduct[];

export type IProductsEntities = INormalizedEntities<IProduct, ProductId>;

export interface IProductsNormalizedData extends IPaginationData {
  ids: ProductId[];
  entities: IProductsEntities;
}

export enum ProductSortType {
  priceAsc = 'za-zrostanniam-tsiny',
  priceDesc = 'za-spadanniam-tsiny',
}
