import { normalize, schema } from 'normalizr';
import {
  IProduct, IProductLabels, IProducts, ProductSortType,
} from '../types/product';
import { getURL, makeRequest } from './api';
import {
  IBaseRequestBodyWithCityCode,
  IBaseResponseBodyWithPagination,
  IPaginationRequestData,
  ISortData,
  RouteQueryParamsKey,
  SortOrder,
} from '../types/api';
import { CategorySlug } from '../types/category';
import { DEFAULT_ITEMS_PER_PAGE } from '../constants/common';

export interface IGetProductsRequestData extends IBaseRequestBodyWithCityCode, IProductLabels, IPaginationRequestData {
  category?: CategorySlug;
  isBrandPage?: boolean;
  [RouteQueryParamsKey.sortType]?: ProductSortType;
}

export interface IGetProductsResponseBody extends IBaseResponseBodyWithPagination<IProducts> {
}

export const getProductsRequest = ({ cityCode, sort, ...restRequestData }: IGetProductsRequestData) => {
  const sortQueryParamsMap: { [key in ProductSortType]: ISortData } = {
    [ProductSortType.priceAsc]: { sortBy: 'price', sortOrder: SortOrder.asc },
    [ProductSortType.priceDesc]: { sortBy: 'price', sortOrder: SortOrder.desc },
  };
  const sortQueryParams = sort && sortQueryParamsMap[sort];

  const url = getURL(
    '/front/product/list',
    {
      ...restRequestData,
      ...sortQueryParams,
      per_page: DEFAULT_ITEMS_PER_PAGE,
    },
  );

  return makeRequest<IGetProductsResponseBody>(url, { cityCode });
};

const productsSchema = new schema.Entity('products', {}, { idAttribute: 'uid' });
const productsListSchema = new schema.Array(productsSchema);

export const normalizeProducts = (data: []) => normalize<IProduct>(data, productsListSchema);
