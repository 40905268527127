import { createReducer } from '@reduxjs/toolkit';
import { IAppReducer } from '../types';
import {
  getCities,
  getCitiesError,
  getCitiesSuccess,
  getCategories,
  getCategoriesError,
  getCategoriesSuccess,
  getBrands,
  getBrandsError,
  getBrandsSuccess,
  setChangeCityDialog,
} from './actions';

const initialState: IAppReducer = {
  isCitiesLoading: false,
  isCategoriesLoading: false,
  isBrandsLoading: false,
  cities: {
    cityCodes: [],
    entities: {},
  },
  categories: {
    slugs: [],
    entities: {},
    list: [],
  },
  brands: {
    slugs: [],
    entities: {},
    available: [],
  },
  changeCityDialog: {
    isOpen: false,
  },
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCities, (state) => ({
    ...state,
    isCitiesLoading: true,
  }));
  builder.addCase(getCitiesSuccess, (state, { payload }) => ({
    ...state,
    cities: payload,
    isCitiesLoading: false,
  }));
  builder.addCase(getCitiesError, (state) => ({
    ...state,
    isCitiesLoading: false,
  }));

  builder.addCase(getCategories, (state) => ({
    ...state,
    isCategoriesLoading: true,
  }));
  builder.addCase(getCategoriesSuccess, (state, { payload }) => ({
    ...state,
    isCategoriesLoading: false,
    categories: {
      ...state.categories,
      ...payload,
    },
  }));
  builder.addCase(getCategoriesError, (state) => ({
    ...state,
    isCategoriesLoading: false,
  }));

  builder.addCase(getBrands, (state) => ({
    ...state,
    isBrandsLoading: true,
  }));
  builder.addCase(getBrandsSuccess, (state, { payload }) => ({
    ...state,
    isBrandsLoading: false,
    brands: payload,
  }));
  builder.addCase(getBrandsError, (state) => ({
    ...state,
    isBrandsLoading: false,
  }));

  builder.addCase(setChangeCityDialog, (state, { payload }) => ({
    ...state,
    changeCityDialog: {
      ...initialState.changeCityDialog,
      ...payload,
    },
  }));
});
