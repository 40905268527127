import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { ICartReducer } from '../types';
import {
  resetCart,
  getCart,
  getCartError,
  getCartSuccess,
  addCartItem,
  addCartItemError,
  addCartItemSuccess,
  putCartItem,
  putCartItemError,
  putCartItemSuccess,
  removeCartItemSuccess,
  removeCartItem,
  removeCartItemError,
  setCartTooltip,
  setCartSyncDialog,
  mergeCartSyncDialog,
  getCartByProfile,
  getCartByProfileError,
  getCartByProfileSuccess,
  syncCartToProfile,
  syncCartToProfileError,
  syncCartToProfileSuccess,
  getCheckoutData,
  getCheckoutDataError,
  getCheckoutDataSuccess,
} from './actions';

export const initialState: ICartReducer = {
  isLoading: false,
  isFormDataLoading: false,
  cartToken: null,
  totalCartPrice: 0,
  totalItemsInCart: 0,
  cartItems: {},
  cartTooltip: {
    isOpen: false,
  },
  cartSyncDialog: {
    isOpen: false,
  },
  formData: null,
};

export const cartReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCartTooltip, (state, { payload }) => ({
    ...state,
    cartTooltip: {
      ...initialState.cartTooltip,
      ...payload,
    },
  }));

  builder.addCase(setCartSyncDialog, (state, { payload }) => ({
    ...state,
    cartSyncDialog: {
      ...initialState.cartSyncDialog,
      ...payload,
    },
  }));
  builder.addCase(mergeCartSyncDialog, (state, { payload }) => ({
    ...state,
    cartSyncDialog: {
      ...state.cartSyncDialog,
      ...payload,
    },
  }));

  builder.addCase(resetCart, () => ({
    ...initialState,
  }));

  builder.addCase(getCheckoutData, (state) => ({
    ...state,
    isFormDataLoading: true,
  }));
  builder.addCase(getCheckoutDataSuccess, (state, { payload }) => ({
    ...state,
    formData: payload,
    isFormDataLoading: false,
  }));
  builder.addCase(getCheckoutDataError, (state) => ({
    ...state,
    isFormDataLoading: false,
  }));

  builder.addMatcher(isAnyOf(
    getCart,
    addCartItem,
    putCartItem,
    removeCartItem,
    getCartByProfile,
    syncCartToProfile,
  ), (state) => ({
    ...state,
    isLoading: true,
  }));

  builder.addMatcher(isAnyOf(
    getCartError,
    addCartItemError,
    putCartItemError,
    removeCartItemError,
    getCartByProfileError,
    syncCartToProfileError,
  ), (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addMatcher(isAnyOf(
    getCartSuccess,
    addCartItemSuccess,
    putCartItemSuccess,
    removeCartItemSuccess,
    getCartByProfileSuccess,
    syncCartToProfileSuccess,
  ), (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
