import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import Router from 'next/router';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  postOrder,
  postOrderSuccess,
  postOrderError,
  getOrder,
  getOrderError,
  getOrderSuccess,
} from './actions';
import { getOrderRequest, postOrderRequest } from '../api';
import { getActiveRouteCityCode, ROUTES_MAP } from '../../../services/routing';
import { CityCode } from '../../../types/city';
import { resetCart } from '../../cart/state/actions';

function* handlePostOrder({ payload }: ReturnType<typeof postOrder>) {
  try {
    const { data } = yield call(postOrderRequest, payload);
    const { orderId } = data;

    yield put(postOrderSuccess());
    yield put(resetCart());

    const cityCode: CityCode = yield call(getActiveRouteCityCode);

    if (Router && cityCode) {
      Router.push(ROUTES_MAP.order.createURL(cityCode, orderId));
    }
  } catch (error) {
    yield put(postOrderError());
    yield handleError(error);
  }
}

function* handleGetOrder({ payload }: ReturnType<typeof getOrder>) {
  try {
    const { data } = yield call(getOrderRequest, payload);

    yield put(getOrderSuccess(data));
  } catch (error) {
    yield put(getOrderError());
    yield handleError(error);
  }
}

export function* orderSagas(): Generator {
  yield all([
    yield takeLatest(
      postOrder,
      handlePostOrder,
    ),
    yield takeLatest(
      getOrder,
      handleGetOrder,
    ),
  ]);
}
