// eslint-disable-next-line
export const API_HOST = process.env.API_HOST;

export const DEFAULT_ITEMS_PER_PAGE = 18;

export const DATE_FORMAT = 'LLL';

export enum DeviceSize {
  desktopMinWidth = 1200,
  tabletMaxWidth = 1024,
  mobileMaxWidth = 736,
}

export enum CookieKey {
  accessToken = 'accessToken',
  userData = 'userData',
  cartToken = 'cartToken',
}

export const DEFAULT_DEBOUNCE_IN_MS = 300;

export const BRAND_INFO_DIALOG_QUERY_PARAM = 'informatsiia-pro-brend';
