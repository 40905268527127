import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getOrdersHistory,
  getOrdersHistorySuccess,
  getOrdersHistoryError,
} from './actions';
import { getOrdersHistoryRequest } from '../api';

function* handleGetOrdersHistory({ payload }: ReturnType<typeof getOrdersHistory>) {
  try {
    const { data } = yield call(getOrdersHistoryRequest, payload);

    yield put(getOrdersHistorySuccess(data));
  } catch (error) {
    yield put(getOrdersHistoryError());
    yield handleError(error);
  }
}

export function* ordersHistorySagas(): Generator {
  yield all([
    yield takeLatest(
      getOrdersHistory,
      handleGetOrdersHistory,
    ),
  ]);
}
