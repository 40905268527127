import { createAction } from '@reduxjs/toolkit';
import {
  ICityGlobalSettingsItems,
  IGetCityGlobalSettingsItemRequestData,
  IGetGlobalSettingsItemRequestData,
  IGlobalSettingsItems,
} from '../types';

export const getGlobalSettings = createAction<IGetGlobalSettingsItemRequestData>('GLOBAL_SETTINGS/GET_GLOBAL_SETTINGS');
export const getGlobalSettingsSuccess = createAction<IGlobalSettingsItems>('GLOBAL_SETTINGS/GET_GLOBAL_SETTINGS/SUCCESS');

export const getCityGlobalSettings = createAction<IGetCityGlobalSettingsItemRequestData>('GLOBAL_SETTINGS/GET_CITY_GLOBAL_SETTINGS');
export const getCityGlobalSettingsSuccess = createAction<ICityGlobalSettingsItems>('GLOBAL_SETTINGS/GET_CITY_GLOBAL_SETTINGS/SUCCESS');
