import { createReducer } from '@reduxjs/toolkit';
import { IProductsReducer } from '../types';
import {
  getProducts,
  getProductsError,
  getProductsFilters,
  getProductsFiltersError,
  getProductsFiltersSuccess,
  getProductsSuccess,
} from './actions';

export const initialState: IProductsReducer = {
  isLoading: false,
  isLoadingFilters: false,
  ids: [],
  entities: {},
  filterGroups: [],
  total: 0,
};

export const productsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProducts, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getProductsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getProductsError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getProductsFilters, (state) => ({
    ...state,
    isLoadingFilters: true,
  }));
  builder.addCase(getProductsFiltersSuccess, (state, { payload }) => ({
    ...state,
    isLoadingFilters: false,
    filterGroups: payload,
  }));
  builder.addCase(getProductsFiltersError, (state) => ({
    ...state,
    isLoadingFilters: false,
  }));
});
