import {
  IGetProductRequestData,
  IGetProductResponseBody,
  IGetSimilarProductsRequestData,
  IGetSimilarProductsResponseBody,
} from './types';
import { getURL, makeRequest } from '../../services/api';

export const getProductRequest = (requestData: IGetProductRequestData) => {
  const { cityCode, ...restRequestData } = requestData;
  const url = getURL('/front/product/one', restRequestData);

  return makeRequest<IGetProductResponseBody>(url, { cityCode });
};

export const getSimilarProductsRequest = (requestData: IGetSimilarProductsRequestData) => {
  const { cityCode, ...restRequestData } = requestData;
  const url = getURL('/front/product/random', restRequestData);

  return makeRequest<IGetSimilarProductsResponseBody>(url, { cityCode });
};
