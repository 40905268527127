import { createReducer } from '@reduxjs/toolkit';
import { IFormsReducer } from '../types';
import { postForm, postFormError, postFormSuccess } from './actions';

const initialState: IFormsReducer = {
  newsSubscription: {
    isLoading: false,
  },
  stillHaveQuestions: {
    isLoading: false,
  },
  wholesaleMembership: {
    isLoading: false,
  },
};

export const formsReducer = createReducer(initialState, ((builder) => {
  builder.addCase(postForm, (state, { payload }) => ({
    ...state,
    [payload.formType]: {
      isLoading: true,
    },
  }));
  builder.addCase(postFormSuccess, (state, { payload }) => ({
    ...state,
    [payload.formType]: {
      isLoading: false,
    },
  }));
  builder.addCase(postFormError, (state, { payload }) => ({
    ...state,
    [payload.formType]: {
      isLoading: false,
    },
  }));
}));
