import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getOurRecommendations,
  getOurRecommendationsError,
  getOurRecommendationsSuccess,
} from './actions';
import { getProductsRequest, normalizeProducts } from '../../../services/product';

function* handleGetProducts({ payload }: ReturnType<typeof getOurRecommendations>) {
  try {
    const { data: responseBody } = yield call(getProductsRequest, payload);
    const { data } = responseBody;

    const { result: ids, entities } = yield call(normalizeProducts, data);

    yield put(getOurRecommendationsSuccess({
      ids,
      entities: entities?.products || {},
      total: 1, // TODO: Remove "total" usage cause is never used anywhere
    }));
  } catch (error) {
    yield put(getOurRecommendationsError());
    yield handleError(error);
  }
}

export function* ourRecommendationsSagas(): Generator {
  yield all([
    yield takeLatest(
      getOurRecommendations,
      handleGetProducts,
    ),
  ]);
}
