import {
  combineReducers, configureStore, Store,
} from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import createSagaMiddleware, { END } from 'redux-saga';
import { all } from 'redux-saga/effects';
import { appReducer } from './features/app/state/reducer';
import { appSagas } from './features/app/state/sagas';
import { homeReducer } from './features/home/state/reducer';
import { homeSagas } from './features/home/state/sagas';
import { globalSettingsSagas } from './features/globalSettings/state/sagas';
import { globalSettingsReducer } from './features/globalSettings/state/reducer';
import { productsReducer } from './features/products/state/reducer';
import { productsSagas } from './features/products/state/sagas';
import { productSagas } from './features/product/state/sagas';
import { productReducer } from './features/product/state/reducer';
import { ourRecommendationsSagas } from './features/ourRecommendations/state/sagas';
import { ourRecommendationsReducer } from './features/ourRecommendations/state/reducer';
import { promotionsReducer } from './features/promotions/state/reducer';
import { promotionsSagas } from './features/promotions/state/sagas';
import { galleryReducer } from './features/gallery/state/reducer';
import { formsSagas } from './features/forms/state/sagas';
import { formsReducer } from './features/forms/state/reducer';
import { profileSagas } from './features/profile/state/sagas';
import { profileReducer } from './features/profile/state/reducer';
import { brandInfoSagas } from './features/brandInfo/state/sagas';
import { brandInfoReducer } from './features/brandInfo/state/reducer';
import { cartReducer } from './features/cart/state/reducer';
import { cartSagas } from './features/cart/state/sagas';
import { orderSagas } from './features/order/state/sagas';
import { orderReducer } from './features/order/state/reducer';
import { ordersHistorySagas } from './features/ordersHistory/state/sagas';
import { ordersHistoryReducer } from './features/ordersHistory/state/reducer';
import { productsSearchSagas } from './features/productsSearch/state/sagas';
import { productsSearchReducer } from './features/productsSearch/state/reducer';
import { debitAndCreditSagas } from './features/debitAndCredit/state/sagas';
import { debitAndCreditReducer } from './features/debitAndCredit/state/reducer';

function* rootSaga() {
  yield all([
    appSagas(),
    homeSagas(),
    globalSettingsSagas(),
    productsSagas(),
    ourRecommendationsSagas(),
    productSagas(),
    promotionsSagas(),
    formsSagas(),
    profileSagas(),
    brandInfoSagas(),
    cartSagas(),
    orderSagas(),
    ordersHistorySagas(),
    productsSearchSagas(),
    debitAndCreditSagas(),
  ]);
}

const combinedReducer = combineReducers({
  appReducer,
  homeReducer,
  globalSettingsReducer,
  productsReducer,
  ourRecommendationsReducer,
  productReducer,
  promotionsReducer,
  galleryReducer,
  formsReducer,
  profileReducer,
  brandInfoReducer,
  cartReducer,
  orderReducer,
  ordersHistoryReducer,
  productsSearchReducer,
  debitAndCreditReducer,
});

const rootReducer = (state: ReturnType<typeof combinedReducer> | undefined, action: any) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return combinedReducer(state, action);
};

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  });

  (store as any).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(createStore);

export const waitAllSagasComplete = async (store: Store) => {
  store.dispatch(END);

  // @ts-ignore
  await store.sagaTask.toPromise();
};
