import { createReducer } from '@reduxjs/toolkit';
import { IHomeReducer } from '../types';
import {
  getHomePageData,
  getHomePageDataError,
  getHomePageDataSuccess,
  getHomePromotions,
  getHomePromotionsError,
  getHomePromotionsSuccess,
} from './actions';

export const initialState: IHomeReducer = {
  isLoading: false,
  isLoadingData: false,
  promotions: [],
  data: {
    title: '',
    text: '',
    phone: '',
  },
};

export const homeReducer = createReducer(initialState, (builder) => {
  builder.addCase(getHomePromotions, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getHomePromotionsSuccess, (state, { payload }) => ({
    ...state,
    promotions: payload,
    isLoading: false,
  }));
  builder.addCase(getHomePromotionsError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getHomePageData, (state) => ({
    ...state,
    isLoadingData: true,
  }));
  builder.addCase(getHomePageDataSuccess, (state, { payload }) => ({
    ...state,
    data: payload,
    isLoadingData: false,
  }));
  builder.addCase(getHomePageDataError, (state) => ({
    ...state,
    isLoadingData: false,
  }));
});
