import { createAction } from '@reduxjs/toolkit';
import { IPostOrderRequestData, IGetOrderRequestData } from '../types';
import { IOrder } from '../../../types/order';

export const postOrder = createAction<IPostOrderRequestData>('ORDER/POST_ORDER');
export const postOrderSuccess = createAction('ORDER/POST_ORDER/SUCCESS');
export const postOrderError = createAction('ORDER/POST_ORDER/ERROR');

export const getOrder = createAction<IGetOrderRequestData>('ORDER/GET_ORDER');
export const getOrderSuccess = createAction<IOrder>('ORDER/GET_ORDER/SUCCESS');
export const getOrderError = createAction('ORDER/GET_ORDER/ERROR');
