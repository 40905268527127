import { createReducer } from '@reduxjs/toolkit';
import { IDebitAndCreditReducer } from '../types';
import {
  getDebitAndCredit,
  getDebitAndCreditSuccess,
  getDebitAndCreditError,
} from './actions';

export const initialState: IDebitAndCreditReducer = {
  isLoading: false,
  comparisonAct: null,
  accountsReceivable: null,
};

export const debitAndCreditReducer = createReducer(initialState, (builder) => {
  builder.addCase(getDebitAndCredit, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getDebitAndCreditSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getDebitAndCreditError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
