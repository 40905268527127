import {
  IGetOrderRequestData, IPostOrderRequestData, IPostOrderResponseBody,
} from './types';
import { getURL, makeRequest } from '../../services/api';
import { IOrder } from '../../types/order';

export const postOrderRequest = (requestData: IPostOrderRequestData) => (
  makeRequest<IPostOrderResponseBody>(getURL('/front/order/confirmOrder'), requestData, 'POST')
);

export const getOrderRequest = ({ orderId, ...restRequestData }: IGetOrderRequestData) => (
  makeRequest<IOrder>(getURL('/front/order/getOrder', { orderId }), restRequestData)
);
