import {
  IGetFiltersRequestData,
  IGetFiltersResponseBody,
} from './types';
import { getURL, makeRequest } from '../../services/api';

export const getFiltersRequest = (requestData: IGetFiltersRequestData) => {
  const { cityCode, ...restRequestData } = requestData;
  const url = getURL('/front/filters/list', restRequestData);

  return makeRequest<IGetFiltersResponseBody>(url, { cityCode });
};
