import { createAction } from '@reduxjs/toolkit';
import {
  IAddCartItemRequestData,
  ICartResponseData,
  IGetCartByProfileRequestData,
  ISyncCartToProfileRequestData,
  ICartTooltip,
  ICartSyncDialog,
  IGetCheckoutRequestData,
  IGetCartRequestData,
  IPutCartItemRequestData,
  IRemoveCartItemRequestData,
  ICheckoutFormValues,
} from '../types';

export const getCart = createAction<IGetCartRequestData>('CART/GET_CART');
export const getCartSuccess = createAction<ICartResponseData>('CART/GET_CART/SUCCESS');
export const getCartError = createAction('CART/GET_CART/ERROR');

export const addCartItem = createAction<IAddCartItemRequestData>('CART/ADD_CART_ITEM');
export const addCartItemSuccess = createAction<ICartResponseData>('CART/ADD_CART_ITEM/SUCCESS');
export const addCartItemError = createAction('CART/ADD_CART_ITEM/ERROR');

export const putCartItem = createAction<IPutCartItemRequestData>('CART/PUT_CART_ITEM');
export const putCartItemSuccess = createAction<ICartResponseData>('CART/PUT_CART_ITEM/SUCCESS');
export const putCartItemError = createAction('CART/PUT_CART_ITEM/ERROR');

export const removeCartItem = createAction<IRemoveCartItemRequestData>('CART/REMOVE_CART_ITEM');
export const removeCartItemSuccess = createAction<ICartResponseData>('CART/REMOVE_CART_ITEM/SUCCESS');
export const removeCartItemError = createAction('CART/REMOVE_CART_ITEM/ERROR');

export const getCartByProfile = createAction<IGetCartByProfileRequestData>('CART/GET_CART_BY_PROFILE');
export const getCartByProfileSuccess = createAction<ICartResponseData>('CART/GET_CART_BY_PROFILE/SUCCESS');
export const getCartByProfileError = createAction('CART/GET_CART_BY_PROFILE/ERROR');

export const syncCartToProfile = createAction<ISyncCartToProfileRequestData>('CART/SYNC_CART_TO_PROFILE');
export const syncCartToProfileSuccess = createAction<ICartResponseData>('CART/SYNC_CART_TO_PROFILE/SUCCESS');
export const syncCartToProfileError = createAction('CART/SYNC_CART_TO_PROFILE/ERROR');

export const setCartTooltip = createAction<Partial<ICartTooltip> | undefined>('CART/CART_TOOLTIP/SET');

export const mergeCartSyncDialog = createAction<Partial<ICartSyncDialog>>('CART/CART_SYNC_DIALOG/MERGE');
export const setCartSyncDialog = createAction<Partial<ICartSyncDialog> | undefined>('CART/CART_SYNC_DIALOG/SET');

export const resetCart = createAction('CART/RESET_CART');

export const getCheckoutData = createAction<IGetCheckoutRequestData>('CART/GET_CHECKOUT_DATA');
export const getCheckoutDataSuccess = createAction<ICheckoutFormValues>('CART/GET_CHECKOUT_DATA/SUCCESS');
export const getCheckoutDataError = createAction('CART/GET_CHECKOUT_DATA/ERROR');
