import {
  ISignUpRequestData,
  ISignUpConfirmRequestData,
  ISignUpResponseBody,
  ISignUpConfirmResponseBody,
  ISignInRequestData,
  ISignInResponseBody,
  IForgotPasswordRequestData,
  IForgotPasswordResponseBody,
  IResetPasswordRequestData,
  IResetPasswordResponseBody,
  IUpdateProfileRequestData,
  IUpdateProfileResponseBody,
  IUpdatePasswordRequestData,
} from './types';
import { getURL, makeRequest } from '../../services/api';

const getClientInfo = async () => {
  const response = await fetch('https://api.db-ip.com/v2/free/self');

  return response.json();
};

async function decorateRequestDataByIp<TData extends object>(data: TData): Promise<TData & { ip: string }> {
  const { ipAddress } = await getClientInfo();

  return {
    ...data,
    ip: ipAddress,
  };
}

export const signUpRequest = async (requestData: ISignUpRequestData) => {
  const requestDataWithIp = await decorateRequestDataByIp(requestData);

  return makeRequest<ISignUpResponseBody>(getURL('/auth/register'), requestDataWithIp, 'POST');
};

export const signUpConfirmRequest = (requestData: ISignUpConfirmRequestData) => (
  makeRequest<ISignUpConfirmResponseBody>(getURL('/auth/activateBySMS'), requestData, 'POST')
);

export const signInRequest = async (requestData: ISignInRequestData) => {
  const requestDataWithIp = await decorateRequestDataByIp(requestData);

  return makeRequest<ISignInResponseBody>(getURL('/auth/login'), requestDataWithIp, 'POST');
};

export const forgotPasswordRequest = async (requestData: IForgotPasswordRequestData) => {
  const requestDataWithIp = await decorateRequestDataByIp(requestData);

  return makeRequest<IForgotPasswordResponseBody>(getURL('/auth/resetPasswordRequest'), requestDataWithIp, 'POST');
};

export const resetPasswordRequest = async (requestData: IResetPasswordRequestData) => {
  const requestDataWithIp = await decorateRequestDataByIp(requestData);

  return makeRequest<IResetPasswordResponseBody>(getURL('/auth/resetPassword'), requestDataWithIp, 'POST');
};

export const updateProfileRequest = (requestData: IUpdateProfileRequestData) => (
  makeRequest<IUpdateProfileResponseBody>(getURL('/auth/updateProfileInfo'), requestData, 'POST')
);

export const updatePasswordRequest = (requestData: IUpdatePasswordRequestData) => (
  makeRequest(getURL('/auth/changePassword'), requestData, 'POST')
);
