import smoothScroll from 'smoothscroll-polyfill';
import { NextPage } from 'next';
import Head from 'next/head';
import Cookies from 'cookies';
import axios from 'axios';
import { wrapper } from '../store';
import { getBrands, getCategories, getCities } from '../features/app/state/actions';
import { CookieKey } from '../constants/common';
import { IProfileData } from '../features/profile/types';
import { signInSuccess } from '../features/profile/state/actions';
import { getCart } from '../features/cart/state/actions';
import { useNotSupportedBrowserAlert } from '../hooks/useNotSupportedBrowserAlert';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/app.scss';

if (typeof window !== 'undefined') {
  smoothScroll.polyfill();
}

interface IAppProps {
  Component: React.ComponentType<any>;
  pageProps: any;
}

const DefaultLayout: React.FC = ({ children }) => (
  <>
    { children }
  </>
);

const App: NextPage<IAppProps> = ({ Component, pageProps }) => {
  // @ts-ignore
  const Layout = Component.getLayout ? Component.getLayout : DefaultLayout;

  useNotSupportedBrowserAlert();

  return (
    <Layout>
      <Head>
        <meta name="color-scheme" content="light" />
      </Head>
      <Component {...pageProps} />
    </Layout>
  );
};

// @ts-ignore TODO: Resolve this ts-ignore after first priority tasks
App.getInitialProps = wrapper.getServerSideProps(async ({ router, store, ctx }) => {
  const { query } = router;
  const { cityCode } = query;
  const { req, res } = ctx;

  const cookies = new Cookies(req, res);
  const accessToken = cookies.get(CookieKey.accessToken);
  const userData = JSON.parse(decodeURIComponent(cookies.get(CookieKey.userData) || '""')) as IProfileData;
  const cartToken = cookies.get(CookieKey.cartToken);

  axios.defaults.headers.common['city-code'] = cityCode || '';
  axios.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';

  if (accessToken && userData) {
    store.dispatch(signInSuccess({
      accessToken,
      data: userData,
    }));
  }

  store.dispatch(getCities());

  if (cityCode) {
    store.dispatch(getCategories({ cityCode }));
    store.dispatch(getBrands({ cityCode }));

    if (cartToken) {
      store.dispatch(getCart({
        cityCode,
        cartToken,
      }));
    }
  }
});

export default wrapper.withRedux(App);
