import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import { getPromotions, getPromotionsError, getPromotionsSuccess } from './actions';
import { getPromotionsRequest } from '../api';
import { IGetPromotionsResponseBody } from '../types';

function* handleGetPromotions({ payload }: ReturnType<typeof getPromotions>) {
  try {
    const { data }: IGetPromotionsResponseBody = yield call(getPromotionsRequest, payload);

    yield put(getPromotionsSuccess(data));
  } catch (error) {
    yield put(getPromotionsError());
    yield handleError(error);
  }
}

export function* promotionsSagas(): Generator {
  yield all([
    yield takeLatest(
      getPromotions,
      handleGetPromotions,
    ),
  ]);
}
