import {
  IGetCitiesResponseBody,
  IGetCategoriesRequestData,
  IGetCategoriesResponseBody,
  IGetBrandsRequestData,
  IGetBrandsResponseBody,
} from './types';
import { getURL, makeRequest } from '../../services/api';

export const getCitiesRequest = () => {
  const url = getURL('/front/mainpage/cities');

  return makeRequest<IGetCitiesResponseBody>(url);
};

export const getCategoriesRequest = (requestData: IGetCategoriesRequestData) => {
  const url = getURL('/front/cat/list');

  return makeRequest<IGetCategoriesResponseBody>(url, requestData);
};

export const getBrandsRequest = (requestData: IGetBrandsRequestData) => {
  const url = getURL('/front/brands/list');

  return makeRequest<IGetBrandsResponseBody>(url, requestData);
};
