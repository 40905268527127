import { createReducer } from '@reduxjs/toolkit';
import { IBrandInfoReducer } from '../types';
import {
  getBrandInfo, getBrandInfoError, getBrandInfoSuccess,
} from './actions';

export const initialState: IBrandInfoReducer = {
  isLoading: false,
  brand: null,
};

export const brandInfoReducer = createReducer(initialState, (builder) => {
  builder.addCase(getBrandInfo, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getBrandInfoSuccess, (state, { payload }) => ({
    ...state,
    brand: payload,
    isLoading: false,
  }));
  builder.addCase(getBrandInfoError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
