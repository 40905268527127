import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  getBrandInfo,
  getBrandInfoSuccess,
  getBrandInfoError,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import { getBrandInfoRequest } from '../api';

export function* handleGetBrandInfo({ payload }: ReturnType<typeof getBrandInfo>) {
  try {
    const { data } = yield call(getBrandInfoRequest, payload);

    yield put(getBrandInfoSuccess(data));
  } catch (error) {
    yield put(getBrandInfoError());
    yield handleError(error);
  }
}

export function* brandInfoSagas(): Generator {
  yield all([
    yield takeLatest(
      getBrandInfo,
      handleGetBrandInfo,
    ),
  ]);
}
