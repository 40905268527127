import { detect } from 'detect-browser';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

export const useNotSupportedBrowserAlert = () => {
  const browser = detect();

  const createMessage = useCallback((browserName: string) => (
    `Ви використовуєте веб-переглядач ${browserName}, який може призвести до не коректного відображення цього веб-сайту.
      \n\nМи рекомендуємо використовувати Google Chrome, Firefox, Safari або Microsoft Edge останньої версії`
  ), []);

  const handleAlertNotify = useCallback((browserName: string | undefined = '') => {
    // eslint-disable-next-line no-alert
    alert(createMessage(browserName));
  }, [createMessage]);

  const handleToastNotify = useCallback((browserName: string | undefined = '') => {
    toast(createMessage(browserName), { autoClose: 8000 });
  }, [createMessage]);

  useEffect(() => {
    switch (browser && browser.name) {
      case 'firefox':
      case 'crios':
      case 'chrome':
      case 'opera':
      case 'safari':
      case 'edge':
      case 'facebook':
      case 'ios':
      case 'edge-ios':
      case 'ios-webview':
      case 'android':
      case 'instagram':
        break;

      case 'opera-mini':
      case 'chromium-webview':
      case 'searchbot':
      case 'samsung':
      case 'miui':
        handleToastNotify(browser?.name);
        break;

      default:
        handleAlertNotify(browser?.name);
    }
  }, [handleToastNotify, handleAlertNotify]);
};
