import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getHomePageData,
  getHomePageDataError,
  getHomePageDataSuccess,
  getHomePromotions,
  getHomePromotionsError,
  getHomePromotionsSuccess,
} from './actions';
import { getHomePromotionsRequest, getHomePageDataRequest } from '../api';

function* handleGetPromotions() {
  try {
    const { data } = yield call(getHomePromotionsRequest);

    yield put(getHomePromotionsSuccess(data));
  } catch (error) {
    yield put(getHomePromotionsError());
    yield handleError(error);
  }
}

function* handleGetHomePageData() {
  try {
    const { data } = yield call(getHomePageDataRequest);

    yield put(getHomePageDataSuccess(data.data));
  } catch (error) {
    yield put(getHomePageDataError());
    yield handleError(error);
  }
}

export function* homeSagas(): Generator {
  yield all([
    yield takeLatest(
      getHomePromotions,
      handleGetPromotions,
    ),
    yield takeLatest(
      getHomePageData,
      handleGetHomePageData,
    ),
  ]);
}
