import { createAction } from '@reduxjs/toolkit';
import {
  ICategoriesStateData,
  IGetCategoriesRequestData,
  IGetBrandsRequestData,
  IBrandsStateData,
  IChangeCityDialog,
  ICitiesStateData,
} from '../types';
import { CityCode } from '../../../types/city';

export const getCities = createAction('APP/GET_CITIES');
export const getCitiesSuccess = createAction<ICitiesStateData>('APP/GET_CITIES/SUCCESS');
export const getCitiesError = createAction('APP/GET_CITIES/ERROR');

export const getCategories = createAction<IGetCategoriesRequestData>('APP/GET_CATEGORIES');
export const getCategoriesSuccess = createAction<ICategoriesStateData>('APP/GET_CATEGORIES/SUCCESS');
export const getCategoriesError = createAction('APP/GET_CATEGORIES/ERROR');

export const getBrands = createAction<IGetBrandsRequestData>('APP/GET_BRANDS');
export const getBrandsSuccess = createAction<IBrandsStateData>('APP/GET_BRANDS/SUCCESS');
export const getBrandsError = createAction('APP/GET_BRANDS/ERROR');

export const setChangeCityDialog = createAction<Partial<IChangeCityDialog> | undefined>('APP/SET_CHANGE_CITY_DIALOG');

export const setActiveCity = createAction<{ cityCode: CityCode }>('APP/SET_ACTIVE_CITY');
