import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getProducts,
  getProductsError,
  getProductsFilters,
  getProductsFiltersError,
  getProductsFiltersSuccess,
  getProductsSuccess,
} from './actions';
import { getFiltersRequest } from '../api';
import { getProductsRequest, normalizeProducts } from '../../../services/product';

function* handleGetProducts({ payload }: ReturnType<typeof getProducts>) {
  try {
    const { data: responseBody } = yield call(getProductsRequest, payload);
    const { data, total } = responseBody;

    const { result: ids, entities } = yield call(normalizeProducts, data);

    yield put(getProductsSuccess({
      ids,
      entities: entities?.products || {},
      total,
    }));
  } catch (error) {
    yield put(getProductsError());
    yield handleError(error);
  }
}

function* handleGetProductsFilters({ payload }: ReturnType<typeof getProductsFilters>) {
  try {
    const { data } = yield call(getFiltersRequest, payload);

    yield put(getProductsFiltersSuccess(data));
  } catch (error) {
    yield put(getProductsFiltersError());
    yield handleError(error);
  }
}

export function* productsSagas(): Generator {
  yield all([
    yield takeLatest(
      getProducts,
      handleGetProducts,
    ),
    yield takeLatest(
      getProductsFilters,
      handleGetProductsFilters,
    ),
  ]);
}
