import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Router from 'next/router';
import {
  getCart,
  addCartItem,
  addCartItemError,
  addCartItemSuccess,
  setCartTooltip,
  getCartByProfile,
  getCartByProfileError,
  getCartByProfileSuccess,
  syncCartToProfile,
  syncCartToProfileError,
  syncCartToProfileSuccess,
  getCartError,
  getCartSuccess,
  putCartItem,
  putCartItemError,
  putCartItemSuccess,
  removeCartItem,
  removeCartItemError,
  removeCartItemSuccess,
  resetCart,
  getCheckoutData,
  getCheckoutDataError,
  getCheckoutDataSuccess,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getCartRequest,
  addCartItemRequest,
  putCartItemRequest,
  removeCartItemRequest,
  syncCartToProfileRequest,
  getCartByProfileRequest,
  getCheckoutDataRequest,
} from '../api';
import { CookieKey } from '../../../constants/common';
import { getActiveRouteCityCode, ROUTES_MAP } from '../../../services/routing';
import { cartReducerSelector } from './selectors';
import { CityCode } from '../../../types/city';
import { ICartResponseData } from '../types';

function* handleGetCart({ payload }: ReturnType<typeof getCart>) {
  try {
    const data: ICartResponseData = yield call(getCartRequest, payload);
    const { cartToken, ...cartData } = data;

    yield put(getCartSuccess({
      cartToken,
      ...cartData,
    }));

    yield call(Cookies.set, CookieKey.cartToken, cartToken);
  } catch (error) {
    yield put(getCartError());
    yield call(handleError, error);
  }
}

function* handleOpenTooltipOnCartChange() {
  const cityCode: CityCode = yield call(getActiveRouteCityCode);
  const { cartTooltip: { isOpen } } = yield select(cartReducerSelector);

  if (Router.asPath !== ROUTES_MAP.basket.createURL(cityCode) && !isOpen) {
    yield put(setCartTooltip({ isOpen: true }));
  }
}

function* handleAddCartItem({ payload }: ReturnType<typeof addCartItem>) {
  try {
    const {
      message, cartToken, ...cartData
    } = yield call(addCartItemRequest, payload);

    yield put(addCartItemSuccess({
      cartToken,
      ...cartData,
    }));

    yield call(Cookies.set, CookieKey.cartToken, cartToken);
    yield call(toast.success, 'Товар додано до кошика');
    yield call(handleOpenTooltipOnCartChange);
  } catch (error) {
    yield put(addCartItemError());
    yield call(handleError, error);
  }
}

function* handlePutCartItem({ payload }: ReturnType<typeof putCartItem>) {
  try {
    const { message, ...cartData } = yield call(putCartItemRequest, payload);

    yield put(putCartItemSuccess({ ...cartData }));
    yield call(handleOpenTooltipOnCartChange);
  } catch (error) {
    yield put(putCartItemError());
    yield call(handleError, error);
  }
}

function* handleRemoveCartItem({ payload }: ReturnType<typeof removeCartItem>) {
  try {
    const { message, ...cartData } = yield call(removeCartItemRequest, payload);

    yield put(removeCartItemSuccess({ ...cartData }));
    yield call(toast.success, 'Товар видалено з кошика');
  } catch (error) {
    yield put(removeCartItemError());
    yield call(handleError, error);
  }
}

function* handleGetCartByProfile({ payload }: ReturnType<typeof getCartByProfile>) {
  try {
    const { cartToken, ...cartData } = yield call(getCartByProfileRequest, payload);

    yield put(getCartByProfileSuccess({
      cartToken,
      ...cartData,
    }));

    yield call(Cookies.set, CookieKey.cartToken, cartToken);
  } catch (error) {
    yield put(getCartByProfileError());
    yield call(handleError, error);
  }
}

function* handleSyncCartToProfile({ payload }: ReturnType<typeof syncCartToProfile>) {
  try {
    const { cartToken, ...cartData } = yield call(syncCartToProfileRequest, payload);

    yield put(syncCartToProfileSuccess({
      cartToken,
      ...cartData,
    }));
    yield call(toast.success, 'Ваш поточний кошик збережено до профілю');
  } catch (error) {
    yield put(syncCartToProfileError());
    yield call(handleError, error);
  }
}

function* handleGetCheckoutData({ payload }: ReturnType<typeof getCheckoutData>) {
  try {
    const { data } = yield call(getCheckoutDataRequest, payload);
    const { formData } = data;

    yield put(getCheckoutDataSuccess(formData));
  } catch (error) {
    yield put(getCheckoutDataError());
    yield call(handleError, error);
  }
}

function* handleCartReset() {
  try {
    Cookies.remove(CookieKey.cartToken);
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* cartSagas(): Generator {
  yield all([
    yield takeLatest(
      getCart,
      handleGetCart,
    ),
    yield takeLatest(
      addCartItem,
      handleAddCartItem,
    ),
    yield takeLatest(
      putCartItem,
      handlePutCartItem,
    ),
    yield takeLatest(
      removeCartItem,
      handleRemoveCartItem,
    ),
    yield takeLatest(
      getCartByProfile,
      handleGetCartByProfile,
    ),
    yield takeLatest(
      syncCartToProfile,
      handleSyncCartToProfile,
    ),
    yield takeLatest(
      getCheckoutData,
      handleGetCheckoutData,
    ),
    yield takeLatest(
      resetCart,
      handleCartReset,
    ),
  ]);
}
