import { createAction } from '@reduxjs/toolkit';
import { IPromotions } from '../../../types/promotion';
import { IHomePageAboutUsData } from '../types';

export const getHomePromotions = createAction('HOME/GET_PROMOTIONS');
export const getHomePromotionsSuccess = createAction<IPromotions>('HOME/GET_PROMOTIONS/SUCCESS');
export const getHomePromotionsError = createAction('HOME/GET_PROMOTIONS/ERROR');

export const getHomePageData = createAction('HOME/GET_DATA');
export const getHomePageDataSuccess = createAction<IHomePageAboutUsData>('HOME/GET_DATA/SUCCESS');
export const getHomePageDataError = createAction('HOME/GET_DATA/ERROR');
