import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getProduct,
  getProductError,
  getProductSuccess,
  getSimilarProducts,
  getSimilarProductsError,
  getSimilarProductsSuccess,
  openProductDialog,
  mergeProductDialog,
} from './actions';
import { getProductRequest, getSimilarProductsRequest } from '../api';
import { IProduct } from '../../../types/product';

function* handleGetProduct({ payload }: ReturnType<typeof getProduct>) {
  try {
    const { data } = yield call(getProductRequest, payload);

    yield put(getProductSuccess(data));
  } catch (error) {
    yield put(getProductError());
    yield handleError(error);
  }
}

const similarProductsSchema = new schema.Entity('products', {}, { idAttribute: 'uid' });
const similarProductsListSchema = new schema.Array(similarProductsSchema);

function* handleGetSimilarProducts({ payload }: ReturnType<typeof getSimilarProducts>) {
  try {
    const { data } = yield call(getSimilarProductsRequest, payload);

    const { result: ids, entities } = normalize<IProduct>(data, similarProductsListSchema);

    yield put(getSimilarProductsSuccess({
      ids,
      entities: entities?.products || {},
    }));
  } catch (error) {
    yield put(getSimilarProductsError());
    yield handleError(error);
  }
}

function* handleOpenProductDialog({ payload }: ReturnType<typeof openProductDialog>) {
  try {
    const { data } = yield call(getProductRequest, payload);

    yield put(mergeProductDialog({ product: data }));
  } catch (error) {
    yield handleError(error);
  }
}

export function* productSagas(): Generator {
  yield all([
    yield takeLatest(
      getProduct,
      handleGetProduct,
    ),
    yield takeLatest(
      getSimilarProducts,
      handleGetSimilarProducts,
    ),
    yield takeLatest(
      openProductDialog,
      handleOpenProductDialog,
    ),
  ]);
}
