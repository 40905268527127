import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

interface IError {
  data: {
    message: string | string[];
  };
}

const getMessageFromError = (error: unknown | IError | Error) => {
  if (error instanceof Error) {
    return error.message;
  }

  const message = (error as IError)?.data?.message;

  let preparedMessage = null;

  if (Array.isArray(message)) {
    preparedMessage = message.join('\n ');
  } else if (typeof message === 'string') {
    preparedMessage = message;
  }

  return preparedMessage;
};

export function* handleError(
  error: unknown | IError | Error,
  defaultErrorMessage = 'Упс! Щось пішло не за планом. Будь ласка, спробуйте пізніше.',
): Generator<any, any, any> {
  const message = getMessageFromError(error);

  // eslint-disable-next-line no-console
  console.error(error);

  yield call(toast.error, message || defaultErrorMessage);
}
