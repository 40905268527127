import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import Router from 'next/router';
import { toast } from 'react-toastify';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getCities,
  getCitiesError,
  getCitiesSuccess,
  getBrands,
  getBrandsError,
  getBrandsSuccess,
  getCategories,
  getCategoriesError,
  getCategoriesSuccess,
  setActiveCity,
} from './actions';
import { getBrandsRequest, getCategoriesRequest, getCitiesRequest } from '../api';
import { IBrand } from '../../../types/brand';
import { ICity } from '../../../types/city';
import { INormalizedCategory } from '../../../types/category';
import { ROUTES_MAP } from '../../../services/routing';
import { profileReducerSelector } from '../../profile/state/selectors';
import { signOut } from '../../profile/state/actions';
import { IGetBrandsResponseBody } from '../types';

const citySchema = new schema.Entity('cities', {}, { idAttribute: 'cityCode' });
const citiesSchema = new schema.Array(citySchema);

function* handleGetCities() {
  try {
    const { data } = yield call(getCitiesRequest);

    const { result: cityCodes, entities } = normalize<ICity>(data, citiesSchema);

    yield put(getCitiesSuccess({
      cityCodes,
      entities: entities?.cities || {},
    }));
  } catch (error) {
    yield put(getCitiesError());
    yield handleError(error);
  }
}

const categorySchema = new schema.Entity('categories', {}, { idAttribute: 'slug' });
const categoriesSchema = new schema.Array(categorySchema);

categorySchema.define({ children: categoriesSchema });

function* handleGetCategories({ payload }: ReturnType<typeof getCategories>) {
  try {
    const { data } = yield call(getCategoriesRequest, payload);

    const { result: slugs, entities } = normalize<INormalizedCategory>(data, categoriesSchema);

    yield put(getCategoriesSuccess({
      slugs,
      entities: entities?.categories || {},
      list: data,
    }));
  } catch (error) {
    yield put(getCategoriesError());
    yield handleError(error);
  }
}

const brandSchema = new schema.Entity('brands', {}, { idAttribute: 'slug' });
const brandsSchema = new schema.Array(brandSchema);

function* handleGetBrands({ payload }: ReturnType<typeof getBrands>) {
  try {
    const { data }: IGetBrandsResponseBody = yield call(getBrandsRequest, payload);
    const { brandsCarousel, brands } = data;

    const { result: slugs, entities } = normalize<IBrand>(brandsCarousel, brandsSchema);

    yield put(getBrandsSuccess({
      slugs,
      entities: entities?.brands || {},
      available: brands,
    }));
  } catch (error) {
    yield put(getBrandsError());
    yield handleError(error);
  }
}

function* handleSetActiveCity({ payload }: ReturnType<typeof setActiveCity>) {
  try {
    const { cityCode } = payload;

    if (Router.router && cityCode) {
      Router.router.push(ROUTES_MAP.cityHome.createURL(cityCode));
    }

    const { data: userData } = yield select(profileReducerSelector);

    if (cityCode !== userData.cityCode) {
      yield put(signOut());
      toast.success('Ви змінили місто. Будь ласка, увійдіть до профілю який прив\'язаний до поточного міста');
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
  }
}

export function* appSagas(): Generator {
  yield all([
    yield takeLatest(
      getCities,
      handleGetCities,
    ),
    yield takeLatest(
      getCategories,
      handleGetCategories,
    ),
    yield takeLatest(
      getBrands,
      handleGetBrands,
    ),
    yield takeLatest(
      setActiveCity,
      handleSetActiveCity,
    ),
  ]);
}
