import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  getDebitAndCredit,
  getDebitAndCreditSuccess,
  getDebitAndCreditError,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import { getDebitAndCreditRequest } from '../api';

export function* handleGetDebitAndCredit({ payload }: ReturnType<typeof getDebitAndCredit>) {
  try {
    const { data } = yield call(getDebitAndCreditRequest, payload);
    const { comparisonAct, accountsReceivable } = data;

    yield put(getDebitAndCreditSuccess({ comparisonAct, accountsReceivable }));
  } catch (error) {
    yield put(getDebitAndCreditError());
    yield call(handleError, error);
  }
}

export function* debitAndCreditSagas(): Generator {
  yield all([
    yield takeLatest(
      getDebitAndCredit,
      handleGetDebitAndCredit,
    ),
  ]);
}
