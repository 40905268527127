import { createReducer } from '@reduxjs/toolkit';
import { IOrdersHistoryReducer } from '../types';
import {
  getOrdersHistory,
  getOrdersHistorySuccess,
  getOrdersHistoryError,
} from './actions';

export const initialState: IOrdersHistoryReducer = {
  isLoading: false,
  total: 0,
  data: null,
};

export const ordersHistoryReducer = createReducer(initialState, (builder) => {
  builder.addCase(getOrdersHistory, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOrdersHistorySuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getOrdersHistoryError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
