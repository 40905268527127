import { createReducer } from '@reduxjs/toolkit';
import { IOurRecommendationsReducer } from '../types';
import {
  getOurRecommendations,
  getOurRecommendationsError,
  getOurRecommendationsSuccess,
} from './actions';

export const initialState: IOurRecommendationsReducer = {
  isLoading: false,
  ids: [],
  entities: {},
  total: 0,
};

export const ourRecommendationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getOurRecommendations, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOurRecommendationsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getOurRecommendationsError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
