import { createReducer } from '@reduxjs/toolkit';
import { IGalleryReducer } from '../types';
import { closeGallery, openGallery } from './actions';

const initialState: IGalleryReducer = {
  isOpen: false,
  initialSlide: 0,
  items: [],
};

export const galleryReducer = createReducer(initialState, (builder) => {
  builder.addCase(openGallery, (state, { payload }) => ({
    ...state,
    ...payload,
    isOpen: true,
  }));
  builder.addCase(closeGallery, (state) => ({
    ...initialState,
  }));
});
