import { createAction } from '@reduxjs/toolkit';
import {
  ISignUpRequestData,
  ISignUpConfirmRequestData,
  ISignInRequestData,
  IForgotPasswordRequestData,
  IResetPasswordRequestData,
  ISignUpConfirmDialog,
  ISignInSuccessData,
  IResetPasswordConfirmDialog,
  IUpdateProfileRequestData,
  IUpdatePasswordRequestData,
  IUpdateProfileSuccessData,
} from '../types';

export const signUp = createAction<ISignUpRequestData>('PROFILE/SIGN_UP');
export const signUpSuccess = createAction('PROFILE/SIGN_UP/SUCCESS');
export const signUpError = createAction('PROFILE/SIGN_UP/ERROR');

export const signUpConfirm = createAction<ISignUpConfirmRequestData>('PROFILE/SIGN_UP_CONFIRM');

export const signIn = createAction<Omit<ISignInRequestData, 'ip'>>('PROFILE/SIGN_IN');
export const signInSuccess = createAction<ISignInSuccessData>('PROFILE/SIGN_IN/SUCCESS');
export const signInError = createAction('PROFILE/SIGN_IN/ERROR');

export const forgotPassword = createAction<IForgotPasswordRequestData>('PROFILE/FORGOT_PASSWORD');
export const forgotPasswordSuccess = createAction('PROFILE/FORGOT_PASSWORD/SUCCESS');
export const forgotPasswordError = createAction('PROFILE/FORGOT_PASSWORD/ERROR');

export const resetPassword = createAction<IResetPasswordRequestData>('PROFILE/RESET_PASSWORD');

export const updateProfile = createAction<IUpdateProfileRequestData>('PROFILE/UPDATE_PROFILE');
export const updateProfileSuccess = createAction<IUpdateProfileSuccessData>('PROFILE/UPDATE_PROFILE/SUCCESS');
export const updateProfileError = createAction('PROFILE/UPDATE_PROFILE/ERROR');

export const updatePassword = createAction<IUpdatePasswordRequestData>('PROFILE/UPDATE_PROFILE_PASSWORD');
export const updatePasswordSuccess = createAction('PROFILE/UPDATE_PROFILE_PASSWORD/SUCCESS');
export const updatePasswordError = createAction('PROFILE/UPDATE_PROFILE_PASSWORD/ERROR');

export const mergeSignUpConfirmDialog = createAction<Partial<ISignUpConfirmDialog>>('PROFILE/SIGN_UP_CONFIRM_DIALOG/MERGE');
export const setSignUpConfirmDialog = createAction<Partial<ISignUpConfirmDialog> | undefined>('PROFILE/SIGN_UP_CONFIRM_DIALOG/SET');

export const mergeResetPasswordConfirmDialog = createAction<Partial<IResetPasswordConfirmDialog>>(
  'PROFILE/RESET_PASSWORD_CONFIRM_DIALOG/MERGE',
);
export const setResetPasswordConfirmDialog = createAction<Partial<IResetPasswordConfirmDialog> | undefined>(
  'PROFILE/RESET_PASSWORD_CONFIRM_DIALOG/SET',
);

export const signOut = createAction('PROFILE/SIGN_OUT');
