import { createReducer } from '@reduxjs/toolkit';
import { IProfileReducer } from '../types';
import {
  signUp,
  signUpSuccess,
  signUpError,
  signIn,
  signInSuccess,
  signInError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  mergeSignUpConfirmDialog,
  setSignUpConfirmDialog,
  mergeResetPasswordConfirmDialog,
  setResetPasswordConfirmDialog,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordError,
  signOut,
} from './actions';

export const initialState: IProfileReducer = {
  isLoading: false,
  accessToken: null,
  data: null,
  signUpConfirmDialog: {
    isLoading: false,
    isOpen: false,
    phone: '',
  },
  resetPasswordConfirmDialog: {
    isLoading: false,
    isOpen: false,
    phone: '',
    password: '',
    repeatPassword: '',
  },
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder.addCase(signUp, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(signUpSuccess, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(signUpError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(signIn, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(signInSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    ...payload,
  }));
  builder.addCase(signInError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(forgotPassword, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(forgotPasswordSuccess, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(forgotPasswordError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(setSignUpConfirmDialog, (state, { payload }) => ({
    ...state,
    signUpConfirmDialog: {
      ...initialState.signUpConfirmDialog,
      ...payload,
    },
  }));
  builder.addCase(mergeSignUpConfirmDialog, (state, { payload }) => ({
    ...state,
    signUpConfirmDialog: {
      ...state.signUpConfirmDialog,
      ...payload,
    },
  }));

  builder.addCase(setResetPasswordConfirmDialog, (state, { payload }) => ({
    ...state,
    resetPasswordConfirmDialog: {
      ...initialState.resetPasswordConfirmDialog,
      ...payload,
    },
  }));
  builder.addCase(mergeResetPasswordConfirmDialog, (state, { payload }) => ({
    ...state,
    resetPasswordConfirmDialog: {
      ...state.resetPasswordConfirmDialog,
      ...payload,
    },
  }));

  builder.addCase(updateProfile, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(updateProfileSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    data: payload,
  }));
  builder.addCase(updateProfileError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(updatePassword, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(updatePasswordSuccess, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(updatePasswordError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(signOut, () => ({
    ...initialState,
  }));
});
